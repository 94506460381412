@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600&display=swap");

@font-face {
  font-family: "Readex Pro", sans-serif;
  src: url("../Fonts/ReadexPro-Regular.woff2");
}

* {
  box-sizing: border-box;
}
.text-12{
  font-size: 12px;
}
.text-14 {
  font-size: 14px;
}
.text-16 {
  font-size: 16px;
}
.text-20{
  font-size: 20px;

}
.support-bot-header {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
.support-bot-sidebar {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.support-bot-sidebar-main-div {
  background-color: #f6f6f6;
  width: 4% !important;
  max-width: 4% !important;
  text-align: center;
  padding-top: 15px;
}
.header-momentum-logo {
  height: 26px;
  cursor: pointer;
}
.main-content {
  min-height: 93.7vh;
  margin-top: 3% !important;
}
.question-text {
  position: fixed;
  width: 100%;
  bottom: 0;
}
.question-text-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f6f6f6;
  padding-top: 20px;
}

.question-textarea {
  width: 50% !important;
}
.communication-icon {
  position: absolute;
  right: 25%;
  bottom: 32px;
  cursor: pointer;
}
.sidebar-menu-img {
  filter: grayscale(100%);
  width: 35px;
  height: 30px;
}
.not-authorize-text {
  font-size: 24px;
}
.support-login-page .content-wrapper {
  background-color: #233646;
}
.login-box {
  background-color: #fff;
  padding: 40px 15px;
  border-radius: 10px;
}
.info-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7d7d7d;
  font-size: 12px;
}
.question-answer-block {
  margin-bottom: 2%;
}
.last-answer-block {
  margin-bottom: 15%;
}
.loader-bottom {
  margin-bottom: 15%;
}
.answer-block {
  text-align: justify;
  font-size: 14px;
}
.source-heading {
  color: #2dacc2;
  display: block;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}
.avtar {
  border: 1px solid;
  border-radius: 60%;
  padding: 5px 7px;
  background-color: #233646;
  color: #fff;
  margin-bottom: 5px;
  font-size: small;
  font-weight: 500;
}

.sources-box {
  background: #fff;
  color: #2dacc2;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
}
.sources-box:hover {
  color: #000 !important;
}
.source-wrap {
  align-items: center;
  flex-direction: row;
  width: fit-content !important;
  flex-wrap: wrap;
}

.countbox {
  padding: 5px 12px;
  border-radius: 4px 0px 0px 4px;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  background: #f0f4f9;
}
.namebox {
  padding: 5px 10px;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
.icon-box i {
  font-size: 25px !important;
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer;
}
.icon-box i.active {
  color: #538953;
}
.feedback-box {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 15px 20px 20px 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.close-feedback {
  float: right !important;
  margin-bottom: 6px;
  cursor: pointer;
}
.landing-page ::-webkit-scrollbar {
  display: none;
} 
.upload-box {
  border: 2px solid #d4d4d4;
  width: 60%;
  border-radius: 10px;
  justify-content: center;
  /* flex-direction: column; */
  text-align: center;
  min-height: 40%;
  position: relative;
}
.upload-box-width {
  border: 2px solid #d4d4d4;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 40%;
  position: relative;
  padding: 10px;
  margin-bottom: 20px;
}
.upload-btn {
  /* position: absolute;
  bottom: 10px;
  right: 10px; */
  text-align: center;
}
.followup-que {
  color: #2dacc2;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  font-size: 14px;
  cursor: pointer;
}
.followup-que:hover {
  color: #000;
  text-decoration: underline;
}
.authenticating-block {
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.continue-btn {
  color: rgb(45 172 194) !important;
  border: 1px solid rgb(12 163 189) !important;
  font-size: 16px;
  background: linear-gradient(170deg, #d1f8ff, #fff) !important;
  border-radius: 8px;
  padding: 4px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.continue-btn-input {
  border: none !important;
  background: linear-gradient(170deg, #d1f8ff, #fff);
}


.back-btn-position{
  position: absolute;
  top: 10px;
}
.success-message-box{
  display: flex;
  align-items: center;
  justify-content: center;
}
.success-message{
  font-size: 16px;
  font-weight: bold;
  padding:  10px;
  background-color: #a0e0c3;
  border:0 solid #00875A;
  color: #00875A; 
  border-radius: 3px;
  
  /* width: 100%; */
}

.custom-file-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.custom-file-label {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  color: #fff;
  background-color: #2dacc2;
  border-radius: 4px;
  text-align: center;
}

.custom-file-label:hover {
  background-color: #2dacc2;
}
.file-name-box{
  display: inline-flex;
  justify-content: start;
  width: 100%;
  flex-wrap: wrap;
}
.file-name{
  text-align: start;
  background: #a9dde6;
  padding: 4px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  min-height: 30px;
}
.show-cursor{
  cursor: pointer;
}
.submit-btn{
  background: #2dacc2 !important;
  color: #fff !important;
  
}
.landing-page-blocks {
  background:  #F0F2F5;
  border-radius: 8px;
  min-height: 220px;
  padding: 10px;
  width: 100%;
  border: 1px solid #D9D9D9;
  padding: 15px;
}
.landing-page-blocks:hover {
  background:  #233646;
  border: 1px solid #233646;
  color: #fff;
}
.landing-page-title {
  font-size: 17px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  /* text-align: center; */
}
.img-span{
  background-color: #233646;
  border-radius: 8px;
  display: inline-block;
  padding: 5px;
}
.landing-page-blocks:hover .img-span{
  background-color: #2DABC1;
}
.choose-file-box{
  border: 1px double #2dacc2;
  padding: 20px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.first-choose-file-box{
  border-right: 0;
}
.custom-file-label2 {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  color: #2dacc2;
  /* background-color: #2dacc2; */
  border-radius: 4px;
  text-align: center;
}

.custom-file-label:hover {
  background-color: #2dacc2;
}
.widget-landing-heading{
font-size: 60px;
font-weight: bold;
text-align: center;
}
.widget-landing-heading span{
  background-color: #dbd5ff57;
  color: #2DABC1;
  border-radius: 12px;
  padding: 0px 3px;
}
.compared-policy-response{
  border-radius: 8px;
  padding: 20px;
}